@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600&family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500&display=swap");

@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:900");

@font-face {
  font-family: "berlin_sans_fb_demibold";

  src: url("./berlin_sans_fb_demi_bold-webfont.woff2") format("woff2"), url("./berlin_sans_fb_demi_bold-webfont.woff") format("woff");

  font-weight: normal;

  font-style: normal;
}

@-webkit-keyframes custom-pulse {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  15% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes custom-pulse {
  0% {
    opacity: 0;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  15% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }

  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

#renderCanvas{
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.game{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

/*@tailwind base;*/

/**
 * Custom base styles
 */

html {
  font-family: 'Poppins', sans-serif;
  background-color: #10181F;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
     -moz-text-size-adjust: 100%;
          text-size-adjust: 100%;
}

body {
  font-size: 13px;
  line-height: 1.4;
  overflow-x: hidden;
}

html, body, #root {
  position: relative;
  margin: 0;
  min-height: 100vh;
}

h1, .h1 {
  font-size: 24px;
}

h2, .h2 {
  font-size: 20px;
}

h3, .h3 {
  font-size: 16px;
}

h4, .h4 {
  font-size: 15px;
}

h5, .h5 {
  font-size: 13px;
}

h6, .h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role=button] {
  text-decoration: none;
}

[role="tooltip"] {
  z-index: 9999;
}

/* Medium Devices, Desktops Only */

@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
  transition: none !important;
  -webkit-animation: none !important;
          animation: none !important;
}

button:focus {
  outline: none;
}

/* Removes webkit's autofill backgorund color */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active
{
  transitionDelay: 9999s;
  transitionProperty: background-color, color;
}

:focus {
  outline-color: transparent;
}

/*fullcalendar Fix*/

.fc-scrollgrid-section-liquid {
  height: 1px !important;
}

.card {
  width:116px;
  height:178px;
  float:left;
  background:#fff;
  border-radius:5px;
  display:table;
  box-sizing:border-box;
  padding:5px;
  margin:10px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size:100px;
  text-shadow:
          1px  1px 0 #000000,
          -1px -1px 0 #000000,
          -1px  1px 0 #000000,
          1px -1px 0 #000000,
          1px  0   0 #000000,
          -1px  0   0 #000000,
          0   -1px 0 #000000,
          0    1px 0 #000000,
          4px  4px 0 #000000;
  box-shadow:0 0 10px #aaaaaa;
  text-align:center;
  position:relative;
}

.noshadow > .card {
  box-shadow: none;
}

.card .inner{
  display:table-cell;
  vertical-align:middle;
  border-radius:5px;
  overflow:hidden;
}

.card .mark{
  display:inline-block;
  vertical-align:middle;
  background:#ffffff;
  margin:auto;
  padding:0 26px;
  border-radius:100px 60px / 120px 60px;
  line-height:1.4;
}

.card .mark.red{
  background:#C72A18;
}

.card .card2-card {
  width: 35px;
  height: 50px;
  border: 2px solid white;
  border-radius: 5px;
}

.card .card2-card:nth-of-type(1) {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: -5px;
  margin-bottom: -10px;
}

.card .card2-card:nth-of-type(2) {
  margin-bottom: 25px;
  margin-right: 5px;
  margin-left: 5px;
}

.card .card4-card {
  width: 28px;
  height: 40px;
  border: 2px solid white;
  border-radius: 5px;
}

.card .card4-card:nth-of-type(1) {
  background:#0063B3;
  margin-top: 10px;
  margin-bottom: -10px;
  margin-left: 30px;
  margin-right: -10px;
}

.card .card4-card:nth-of-type(2) {
  background:#18A849;
  margin-left: 20px;
}

.card .card4-card:nth-of-type(3) {
  background:#C72A18;
  margin-top: -60px;
  margin-left: 5px;
}

.card .card4-card:nth-of-type(4) {
  background:#E6CA1E;
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -5px;
}

.card:before,
.card:after{
  display:inline-block;
  position:absolute;
  line-height:0;
  font-size:20px;
  color:#ffffff;
  text-shadow:
          1px  1px 0 #000000,
          -1px -1px 0 #000000,
          -1px  1px 0 #000000,
          1px -1px 0 #000000,
          1px  0   0 #000000,
          -1px  0   0 #000000,
          0   -1px 0 #000000,
          0    1px 0 #000000,
          2px  2px 0 #000000;
}

.card:before{
  top:15px;
  left:10px;
}

.card:after{
  bottom:15px;
  right:10px;
  -webkit-transform:rotate(180deg)
}

.card.blue{
  color:#0063B3;
}

.card.blue .inner{
  background:#0063B3;
}

.card.blue .card2-card{
  background:#0063B3;
}

.card.green{
  color:#18A849;
}

.card.green .inner{
  background:#18A849;
}

.card.green .card2-card{
  background:#18A849;
}

.card.red{
  color:#C72A18;
}

.card.red .inner{
  background:#C72A18;
}

.card.red .card2-card{
  background:#C72A18;
}

.card.yellow{
  color:#E6CA1E;
}

.card.yellow .inner{
  background:#E6CA1E;
}

.card.yellow .card2-card{
  background:#E6CA1E;
}

.card.black{
  color: #000000;
}

.card.black .inner{
  background:#000000;
}

.card.num-0:before,
.card.num-0:after{
  content:"0";
}

.card.num-1:before,
.card.num-1:after{
  content:"1";
}

.card.num-2:before,
.card.num-2:after{
  content:"2";
}

.card.num-3:before,
.card.num-3:after{
  content:"3";
}

.card.num-4:before,
.card.num-4:after{
  content:"4";
}

.card.num-5:before,
.card.num-5:after{
  content:"5";
}

.card.num-6:before,
.card.num-6:after{
  content:"6";
}

.card.num-7:before,
.card.num-7:after{
  content:"7";
}

.card.num-8:before,
.card.num-8:after{
  content:"8";
}

.card.num-9:before,
.card.num-9:after{
  content:"9";
}

.card.num-plus2:before,
.card.num-plus2:after{
  content:"+2";
}

.card.num-wild4:before,
.card.num-wild4:after{
  content:"+4";
}

.card.num-reverse .arrows {
  margin-top: -5px;
  margin-bottom: 5px;
  margin-left: 17px;
  margin-right: -3px;
  display: inline-block;
  -webkit-transform: rotate(-45deg) scale(0.65);
          transform: rotate(-45deg) scale(0.65);
}

.card.num-reverse .arrows .arrow {
  width: 40px;
  height: 40px;
  position: relative;
}

.card.num-reverse .arrows .arrow:before {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background-color: inherit;
  border-top-left-radius: 100%;
  position: absolute;
  left: -39px;
}

.card.num-reverse .arrows .arrow:after {
  content: '';
  display: block;
  width: 0px;
  height: 0px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right: none;
  position: absolute;
  right: -39px;
  top: -20px;
}

.card.num-reverse .arrows .arrow:last-child {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  top: 8px;
  right: 53.33333333px;
}

.card.green .arrow:after {
  border: 40px solid #18A849;
}

.card.yellow .arrow:after {
  border: 40px solid #E6CA1E;
}

.card.red .arrow:after {
  border: 40px solid #C72A18;
}

.card.blue .arrow:after {
  border: 40px solid #0063B3;
}

.card.num-6 .mark:after,
.card.num-9 .mark:after{
  display:block;
  content:"";
  position:relative;
  top:-25px;
  left:5px;
  width:80%;
  border:1px solid #000000;
  height:4px;
  box-shadow:1px 1px 0 #000000;
}

.card.num-6.blue .mark:after,
.card.num-9.blue .mark:after,
.card.blue.num-reverse .arrows .arrow {
  background:#0063B3;
}

.card.num-6.green .mark:after,
.card.num-9.green .mark:after,
.card.green.num-reverse .arrows .arrow {
  background:#18A849;
}

.card.num-6.red .mark:after,
.card.num-9.red .mark:after,
.card.red.num-reverse .arrows .arrow {
  background:#C72A18;
}

.card.num-6.yellow .mark:after,
.card.num-9.yellow .mark:after,
.card.yellow.num-reverse .arrows .arrow {
  background:#E6CA1E;
}

.card.num-wild .wild {
  width: 50%;
  height: 50%;
}

.card.num-wild .wild.red {
  background-color: #C11F1F;
}

.card.num-wild .wild.green {
  background-color: #3E9E32;
}

.card.num-wild .wild.yellow {
  background-color: #DED71F;
}

.card.num-wild .wild.blue {
  background-color: #3F4CFF;
}

.card-hover:hover .card{
  -webkit-transform:scale(1.1) translate(0,-130px);
          transform:scale(1.1) translate(0,-130px);
  transition:-webkit-transform 0.2s ease-in-out;
  transition:transform 0.2s ease-in-out;
  transition:transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.highlight .card{
  -webkit-transform:scale(1.1) translate(0,-40px);
          transform:scale(1.1) translate(0,-40px);
  transition:-webkit-transform 0.2s ease-in-out;
  transition:transform 0.2s ease-in-out;
  transition:transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.key-button {
  box-sizing: border-box;
  line-height: 40px;
  font-size: 22px;
  text-align: center;
  width: 40px;
  color: #555;
  cursor: pointer;
  height: 40px;
  border-color: #f2f2f2;
  border-style: solid;
  text-shadow: 0 0.5px 1px #777, 0 2px 6px #f2f2f2;
  border-width: 1px;
  border-radius: 5px;
  background: -webkit-linear-gradient(top, #f9f9f9 0%, #D2D2D2 80%, #c0c0c0 100%);
  font-family: sans-serif;
  display: inline-block;
  transition: box-shadow 0.3s ease, -webkit-transform 0.15s ease;
  transition: box-shadow 0.3s ease, transform 0.15s ease;
  transition: box-shadow 0.3s ease, transform 0.15s ease, -webkit-transform 0.15s ease;
  box-shadow: 0 0 1px #888, 0 1px 0 #fff, 0 6px 0 #C0C0C0, 0 8px 17px rgba(#444, 0.4), 2px 1px 4px rgba(#444, 0.25), -2px 1px 4px rgba(#444, 0.25), 0 9px 16px rgba(#444, 0.1);
}

.container{
  width: 100%;
}

@media (min-width: 640px){
  .container{
    max-width: 640px;
  }
}

@media (min-width: 768px){
  .container{
    max-width: 768px;
  }
}

@media (min-width: 1024px){
  .container{
    max-width: 1024px;
  }
}

@media (min-width: 1280px){
  .container{
    max-width: 1280px;
  }
}

@media (min-width: 1536px){
  .container{
    max-width: 1536px;
  }
}

.fixed{
  position: fixed !important;
}

.absolute{
  position: absolute !important;
}

.relative{
  position: relative !important;
}

.top-0{
  top: 0 !important;
}

.left-0{
  left: 0 !important;
}

.right-6{
  right: 0.6rem !important;
}

.left-10{
  left: 1.0rem !important;
}

.top-10{
  top: 1.0rem !important;
}

.right-10{
  right: 1.0rem !important;
}

.bottom-0{
  bottom: 0 !important;
}

.z-50{
  z-index: 50 !important;
}

.m-auto{
  margin: auto !important;
}

.mx-10{
  margin-left: 1.0rem !important;
  margin-right: 1.0rem !important;
}

.mt-32{
  margin-top: 3.2rem !important;
}

.mr-10{
  margin-right: 1.0rem !important;
}

.ml-10{
  margin-left: 1.0rem !important;
}

.mt-16{
  margin-top: 1.6rem !important;
}

.mb-20{
  margin-bottom: 2rem !important;
}

.ml-6{
  margin-left: 0.6rem !important;
}

.mt-20{
  margin-top: 2rem !important;
}

.mb-8{
  margin-bottom: 0.8rem !important;
}

.mr-14{
  margin-right: 1.4rem !important;
}

.ml-4{
  margin-left: 0.4rem !important;
}

.mt-4{
  margin-top: 0.4rem !important;
}

.mb-0{
  margin-bottom: 0 !important;
}

.mt-10{
  margin-top: 1.0rem !important;
}

.mt-2{
  margin-top: 0.2rem !important;
}

.mt-6{
  margin-top: 0.6rem !important;
}

.ml-20{
  margin-left: 2rem !important;
}

.mb-24{
  margin-bottom: 2.4rem !important;
}

.mt-52{
  margin-top: 5.2rem !important;
}

.block{
  display: block !important;
}

.inline-block{
  display: inline-block !important;
}

.flex{
  display: flex !important;
}

.table{
  display: table !important;
}

.hidden{
  display: none !important;
}

.h-screen{
  height: 100vh !important;
}

.h-44{
  height: 4.4rem !important;
}

.h-full{
  height: 100% !important;
}

.h-32{
  height: 3.2rem !important;
}

.h-72{
  height: 7.2rem !important;
}

.h-24{
  height: 2.4rem !important;
}

.w-screen{
  width: 100vw !important;
}

.w-44{
  width: 4.4rem !important;
}

.w-1\/3{
  width: 33.333333% !important;
}

.w-72{
  width: 7.2rem !important;
}

.w-32{
  width: 3.2rem !important;
}

.w-full{
  width: 100% !important;
}

.w-136{
  width: 13.6rem !important;
}

.w-40{
  width: 4rem !important;
}

.w-lg{
  width: 80rem !important;
}

.w-24{
  width: 2.4rem !important;
}

.w-92{
  width: 9.2rem !important;
}

.w-1\/2{
  width: 50% !important;
}

.w-76{
  width: 7.6rem !important;
}

.min-w-xl{
  min-width: 96rem !important;
}

.min-w-52{
  min-width: 5.2rem !important;
}

.min-w-84{
  min-width: 8.4rem !important;
}

.max-w-4xl{
  max-width: 56rem !important;
}

.max-w-2xl{
  max-width: 42rem !important;
}

.rotate-45{
  --tw-rotate: 45deg !important;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.transform{
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.cursor-pointer{
  cursor: pointer !important;
}

.cursor-default{
  cursor: default !important;
}

.resize{
  resize: both !important;
}

.flex-row{
  flex-direction: row !important;
}

.flex-col{
  flex-direction: column !important;
}

.flex-wrap{
  flex-wrap: wrap !important;
}

.items-start{
  align-items: flex-start !important;
}

.items-center{
  align-items: center !important;
}

.justify-start{
  justify-content: flex-start !important;
}

.justify-center{
  justify-content: center !important;
}

.justify-between{
  justify-content: space-between !important;
}

.justify-around{
  justify-content: space-around !important;
}

.overflow-hidden{
  overflow: hidden !important;
}

.overflow-y-scroll{
  overflow-y: scroll !important;
}

.rounded-lg{
  border-radius: 0.5rem !important;
}

.rounded-none{
  border-radius: 0px !important;
}

.rounded-md{
  border-radius: 0.375rem !important;
}

.rounded-l-none{
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.border-0{
  border-width: 0px !important;
}

.border{
  border-width: 1px !important;
}

.border-2{
  border-width: 2px !important;
}

.border-r-2{
  border-right-width: 2px !important;
}

.border-solid{
  border-style: solid !important;
}

.border-green-400{
  --tw-border-opacity: 1 !important;
  border-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.border-red-400{
  --tw-border-opacity: 1 !important;
  border-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.border-primary-500{
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 187 255 / var(--tw-border-opacity)) !important;
}

.border-yellow-400{
  --tw-border-opacity: 1 !important;
  border-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.border-blue-400{
  --tw-border-opacity: 1 !important;
  border-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.border-purple-400{
  --tw-border-opacity: 1 !important;
  border-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.border-red-300{
  --tw-border-opacity: 1 !important;
  border-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.border-yellow-300{
  --tw-border-opacity: 1 !important;
  border-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.border-blue-300{
  --tw-border-opacity: 1 !important;
  border-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.border-green-300{
  --tw-border-opacity: 1 !important;
  border-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.border-purple-300{
  --tw-border-opacity: 1 !important;
  border-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.bg-gray-900{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity)) !important;
}

.bg-green-200{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity)) !important;
}

.bg-red-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity)) !important;
}

.bg-transparent{
  background-color: transparent !important;
}

.bg-red-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity)) !important;
}

.bg-yellow-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity)) !important;
}

.bg-blue-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity)) !important;
}

.bg-green-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity)) !important;
}

.bg-purple-300{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity)) !important;
}

.bg-primary-500{
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 187 255 / var(--tw-bg-opacity)) !important;
}

.p-6{
  padding: 0.6rem !important;
}

.p-10{
  padding: 1.0rem !important;
}

.py-10{
  padding-top: 1.0rem !important;
  padding-bottom: 1.0rem !important;
}

.px-24{
  padding-left: 2.4rem !important;
  padding-right: 2.4rem !important;
}

.py-8{
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.py-0{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.px-8{
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}

.py-4{
  padding-top: 0.4rem !important;
  padding-bottom: 0.4rem !important;
}

.py-6{
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

.px-6{
  padding-left: 0.6rem !important;
  padding-right: 0.6rem !important;
}

.py-1{
  padding-top: 0.1rem !important;
  padding-bottom: 0.1rem !important;
}

.py-2{
  padding-top: 0.2rem !important;
  padding-bottom: 0.2rem !important;
}

.px-20{
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.pb-10{
  padding-bottom: 1.0rem !important;
}

.pb-4{
  padding-bottom: 0.4rem !important;
}

.pt-4{
  padding-top: 0.4rem !important;
}

.pt-2{
  padding-top: 0.2rem !important;
}

.pt-10{
  padding-top: 1.0rem !important;
}

.pr-2{
  padding-right: 0.2rem !important;
}

.pt-20{
  padding-top: 2rem !important;
}

.pr-20{
  padding-right: 2rem !important;
}

.text-center{
  text-align: center !important;
}

.text-3xl{
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.text-xl{
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.text-lg{
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.text-5xl{
  font-size: 3rem !important;
  line-height: 1 !important;
}

.text-sm{
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.text-xs{
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.font-bold{
  font-weight: 700 !important;
}

.uppercase{
  text-transform: uppercase !important;
}

.text-black{
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.text-white{
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.text-green-800{
  --tw-text-opacity: 1 !important;
  color: rgb(22 101 52 / var(--tw-text-opacity)) !important;
}

.text-green-500{
  --tw-text-opacity: 1 !important;
  color: rgb(34 197 94 / var(--tw-text-opacity)) !important;
}

.text-red-500{
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.text-green-400{
  --tw-text-opacity: 1 !important;
  color: rgb(74 222 128 / var(--tw-text-opacity)) !important;
}

.text-red-400{
  --tw-text-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-text-opacity)) !important;
}

.text-yellow-300{
  --tw-text-opacity: 1 !important;
  color: rgb(253 224 71 / var(--tw-text-opacity)) !important;
}

.opacity-20{
  opacity: 0.2 !important;
}

.opacity-80{
  opacity: 0.8 !important;
}

.opacity-60{
  opacity: 0.6 !important;
}

.opacity-50{
  opacity: 0.5 !important;
}

.opacity-75{
  opacity: 0.75 !important;
}

.opacity-40{
  opacity: 0.4 !important;
}

.opacity-10{
  opacity: 0.1 !important;
}

.opacity-30{
  opacity: 0.3 !important;
}

.opacity-100{
  opacity: 1 !important;
}

.blur{
  --tw-blur: blur(8px) !important;
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.filter{
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.transition{
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-transform, -webkit-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.ease-out{
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

@media (min-width: 640px){
  .sm\:text-lg{
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }
}

@media (min-width: 768px){
  .md\:-mt-20{
    margin-top: -2rem !important;
  }

  .md\:text-xl{
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }
}